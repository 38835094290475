import React from "react";
import { Grid, Typography } from "@mui/material";
import TitlebarBelowImageList from "../components/titlebarBelowImageList";

const OtherItems = ({ cols }) => {
  return (
    <>
      <Grid maxWidth="lg">
        <Grid item xs={12}>
          <Typography component="h2" variant="h4" sx={{ mt: 2 }}>
            You Might Also Like
          </Typography>
          <TitlebarBelowImageList cols={cols} />
        </Grid>
      </Grid>
    </>
  );
};

export default OtherItems;
