import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import LaunchIcon from "@mui/icons-material/Launch";
import Layout from "../components/layout";
import TagsList from "../components/tagsList";
import HowToAccordion from "../components/howToAccordion";
import OtherItems from "../components/otherItems";
import SocialShare from "../components/socialshare";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MessagesTemplate = ({ data }) => {
  const image = getImage(data.messagesJson.imageURL);
  const licensePathname = `${data.messagesJson.path}license/`;
  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `${data.messagesJson.imageURL.name}.${data.messagesJson.imageURL.extension}`;
    link.href = data.messagesJson.imageURL.publicURL;
    link.click();
  };

  return (
    <>
      <Layout
        title={data.messagesJson.title}
        pathname={data.messagesJson.path}
        image={data.messagesJson.imageURL.gatsbyImageData}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h3" component="h1">
              {data.messagesJson.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper elevation={3}>
              <GatsbyImage image={image} alt={data.messagesJson.altText} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 2, mb: 2 }}>
            <SocialShare idval="widget-1653533390807" />
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={onDownload}
            >
              Download (1920x1080px)
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              endIcon={<LaunchIcon />}
              href={data.messagesJson.imageURL.publicURL}
            >
              Open Image in New Window
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="outlined"
              color="secondary"
              href={licensePathname}
            >
              License
            </Button>
          </Grid>

          <Grid item xs={12} md={8}>
            <TagsList data={data.messagesJson} />
            <HowToAccordion />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              <OtherItems cols={4} maxResults={4} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", md: "none" },
              }}
            >
              <OtherItems cols={2} maxResults={4} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", sm: "none", md: "none" },
              }}
            >
              <OtherItems cols={1} maxResults={4} />
            </Box>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

MessagesTemplate.propTypes = {
  data: PropTypes.shape({
    messagesJson: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      altText: PropTypes.string.isRequired,
      tags: PropTypes.array,
      imageURL: PropTypes.shape({
        publicURL: PropTypes.string,
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.object,
        }),
      }),
    }),
  }),
};

export default MessagesTemplate;

export const query = graphql`
  query messagesByPath($path: String!) {
    messagesJson(path: { eq: $path }) {
      id
      path
      tags
      title
      altText
      imageURL {
        publicURL
        name
        extension
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
