import React from "react";
import kebabCase from "lodash/kebabCase";
import { styled } from "@mui/material/styles";
import { Box, Chip, Paper, Typography } from "@mui/material";
import { Link } from "gatsby";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const TagsList = ({ data }) => {
  return (
    <>
      <Typography component="h2" variant="h4" sx={{ mt: 1 }}>
        Tags
      </Typography>
      <Box>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            justifyContent: "left",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0.5,
          }}
          component="ul"
        >
          {data.tags.map((item) => {
            return (
              <ListItem key={item}>
                <Link
                  to={`/tags/${kebabCase(item)}/`}
                  style={{ textDecoration: "none" }}
                >
                  <Chip label={item} sx={{ fontWeight: "bold" }} />
                </Link>
              </ListItem>
            );
          })}
        </Paper>
      </Box>
    </>
  );
};

export default TagsList;
